import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

// --------------------------------------------------

export default function SitemarkIcon() {
  return (
    <SvgIcon sx={{ height: 21, width: 75, mx: 2, mt: -0.45 }}>
      <svg
        viewBox="0 0 86 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="#3381ff" stroke-width="0" d="M11.7,18.6h-2c-.3,0-.5-.2-.5-.5v-6.7c0-.8-.2-1.5-.6-1.9-.4-.5-1-.7-1.8-.7h-2.8c-.3,0-.5.2-.5.5v8.8c0,.3-.2.5-.5.5H.9c-.3,0-.5-.2-.5-.5V6.4c0-.3.2-.5.5-.5h6.2c1.6,0,2.9.5,3.8,1.4.9.9,1.4,2.2,1.4,3.8v7c0,.3-.2.5-.5.5Z"/>
        <path fill="#3381ff" stroke-width="0" d="M15.9,18.6h-1.9c-.3,0-.6-.2-.6-.6V6.4c0-.3.2-.6.6-.6h1.9c.3,0,.6.2.6.6v11.7c0,.3-.2.6-.6.6ZM15,4.3h0c-.8,0-1.5-.7-1.5-1.5h0c0-.9.7-1.6,1.5-1.6h0c.8,0,1.5.7,1.5,1.5h0c0,.9-.7,1.6-1.5,1.6Z"/>
        <path fill="#3381ff" stroke-width="0" d="M28.3,18.6h-2.1c-.3,0-.5-.2-.5-.5v-2.9c0-.3,0-.6,0-.9,0-.3-.2-.6-.4-.8-.2-.3-.4-.5-.8-.6-.3-.2-.7-.2-1.2-.2h-1.7c-.2,0-.3.1-.3.3v5.2c0,.3-.2.5-.5.5h-2.1c-.3,0-.5-.2-.5-.5V.9c0-.3.2-.5.5-.5h2.1c.3,0,.5.2.5.5v8.9h.5l3.1-3.2c.4-.4,1-.7,1.6-.7h2.4c.2,0,.4.3.2.5l-3.3,3.4c-.1.1,0,.3,0,.4.3.2.6.4.9.7.4.3.7.7,1,1.2.3.4.5.9.7,1.4.2.5.2,1,.2,1.5v3.2c0,.3-.2.5-.5.5Z"/>
        <path fill="#3381ff" stroke-width="0" d="M36.4,18.6c-1,0-1.9-.2-2.7-.5-.8-.3-1.5-.8-2.1-1.4s-1.1-1.3-1.4-2.1c-.3-.8-.5-1.7-.5-2.7s.2-1.9.5-2.7.8-1.5,1.4-2.1c.6-.6,1.3-1,2.1-1.3.8-.3,1.7-.5,2.7-.5s1.9.2,2.7.5c.8.3,1.5.8,2.1,1.3.6.6,1.1,1.3,1.4,2.1.3.8.5,1.7.5,2.7s-.2,1.9-.5,2.7c-.3.8-.8,1.5-1.4,2.1s-1.3,1-2.1,1.4c-.8.3-1.7.5-2.7.5ZM36.4,8.4c-1.1,0-2,.3-2.6,1-.7.7-1,1.5-1,2.6s.3,2,1,2.7c.7.7,1.5,1,2.6,1s2-.3,2.6-1c.7-.7,1-1.6,1-2.7s-.3-2-1-2.6-1.5-1-2.6-1Z"/>
        <path fill="#3381ff" stroke-width="0" d="M50.4,18.6c-1,0-1.9-.2-2.7-.5-.8-.3-1.5-.8-2.1-1.4s-1.1-1.3-1.4-2.1c-.3-.8-.5-1.7-.5-2.7s.2-1.9.5-2.7.8-1.5,1.4-2.1c.6-.6,1.3-1,2.1-1.3.8-.3,1.7-.5,2.7-.5s1.9.2,2.7.5c.8.3,1.5.8,2.1,1.3.6.6,1.1,1.3,1.4,2.1.3.8.5,1.7.5,2.7s-.2,1.9-.5,2.7c-.3.8-.8,1.5-1.4,2.1s-1.3,1-2.1,1.4c-.8.3-1.7.5-2.7.5ZM50.4,8.4c-1.1,0-2,.3-2.6,1-.7.7-1,1.5-1,2.6s.3,2,1,2.7c.7.7,1.5,1,2.6,1s2-.3,2.6-1c.7-.7,1-1.6,1-2.7s-.3-2-1-2.6-1.5-1-2.6-1Z"/>
        <circle fill="#3381ff" stroke-width="0" cx="15" cy="2.8" r="2"/>
      </svg>
    </SvgIcon>
  );
}
